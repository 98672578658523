import React, { createContext, useState, ReactNode, useContext } from "react";
import { Confirm } from "../../types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const ConfirmationDialogContext = createContext({});

/**
 *
 * @param {Confirm & {
 *  setConfirm: Dispatch<SetStateAction<Confirm>>
 * }} props
 */
function ConfirmationDialog({
  isOpen,
  isResolving,
  prompt = "Are you sure?",
  title = "Confirm",

  resolve,
  reject,
}) {
  function handleClose() {
    reject?.();
  }

  function handleConfirm() {
    resolve?.();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={!isResolving && handleClose}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={!isResolving && handleClose}>
        <span style={{ color: "black" }}>{title}</span>
      </ModalHeader>

      <ModalBody className="m-2">{prompt}</ModalBody>

      <ModalFooter>
        <Button
          color="danger"
          outline
          onClick={handleConfirm}
          disabled={isResolving}
        >
          {isResolving ? <Spinner size="sm" /> : "Yes"}
        </Button>
        <Button
          color="secondary"
          outline
          onClick={() => {
            handleClose();
          }}
          disabled={isResolving}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
}

/**
 *
 * @param {{
 *  children?: ReactNode
 * }} props
 * @returns
 */
function ConfirmationDialogProvider({ children }) {
  /**
   * @type {Confirm}
   */
  const defaultProps = {
    prompt: "",
    title: "",
    isOpen: false,
    isResolving: false,
    resolve: null,
    reject: null,
  };
  const [confirm, setConfirm] = useState(defaultProps);

  return (
    <ConfirmationDialogContext.Provider value={{ ...confirm, setConfirm }}>
      <ConfirmationDialog {...confirm} setConfirm={setConfirm} />
      {children}
    </ConfirmationDialogContext.Provider>
  );
}

function useConfirm() {
  const { setConfirm } = useContext(ConfirmationDialogContext);

  /**
   *
   * @param {Pick<Confirm, "prompt" | "title">} options
   */
  function confirm(options) {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        ...options,
        isOpen: true,
        isResolving: false,
        resolve,
        reject,
      });
    });

    return promise.then(
      () => {
        // resolve
        setConfirm((current) => ({ ...current, isResolving: true }));

        const closeDialog = () => {
          setConfirm((current) => ({ ...current, isOpen: false }));
        };
        return { closeDialog };
      },
      () => {
        // reject
        setConfirm((current) => ({ ...current, isOpen: false }));
        throw new Error("Promise was rejected");
      }
    );
  }

  return { confirm };
}

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirm };
